import 'react-popper-tooltip/dist/styles.css'
import React from 'react'
import classNames from 'classnames'
import './customTooltip.scss'
import { ReactComponent as Pois } from '../../assets/icon/icon.svg'
import { Tooltip } from 'react-tooltip'
import { useSelector } from 'react-redux'

export const CustomTooltip = ({ isMainFrame, poi, goToPoi, currPoi }) => {
  const getPoi = useSelector(state => state.helpers.poi)
  const findCurrent = getPoi.find(point => point.id === poi.id)
  const isHaveOwn = findCurrent?.hasOwnProperty('isShowOnMinimap')
  const isShowOnMinimap = (findCurrent && isHaveOwn) ? findCurrent.isShowOnMinimap : true
  return (
    <>
      <div style={{
        display:isShowOnMinimap ? 'block' : 'none'
      }}>
        <Tooltip
          anchorSelect={`#my-anchor-${poi.id}`}
          content={poi.name}
          className='tooltip-container'
          place='right'
        />
        {poi.icon ? <img
            id={`my-anchor-${poi.id}`}
            src={poi.icon}
            onClick={(e) => {
              goToPoi(poi)
            }}
            className={classNames({
              'minimap-poi': true,
              'minimap-poi--small': isMainFrame
            })}
            style={{ left: `${poi.px.right}`, bottom: `${poi.px.bottom}`, pointerEvents: 'initial' }}
          ></img> :
          <Pois
            id={`my-anchor-${poi.id}`}
            style={{
              fill: poi.backgroundColor,
              left: `${poi.px.right}`,
              bottom: `${poi.px.bottom}`,
              pointerEvents: 'initial'
            }}
            onClick={(e) => {
              goToPoi(poi)
            }}
            className={classNames({
              'minimap-poi': true,
              'minimap-poi--small': isMainFrame
            })}
          />
        }
      </div>
    </>
  )
}