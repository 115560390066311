// auth
export const SIGN_IN = 'SIGN_IN';
export const SIGN_UP = 'SIGN_UP';

//helpers
export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';
export const SHOW_ERROR_MODAL = 'SHOW_ERROR_MODAL';
export const HIDE_ERROR_MODAL = 'HIDE_ERROR_MODAL';

// language
export const SET_LANGUAGE = 'SET_LANGUAGE';

//POI
export const SET_POI = 'SET_POI';
export const SET_POI_CHECKED = 'SET_POI_CHECKED';

// color
export const SET_CONFIGS = 'SET_CONFIGS';

// space
export const SET_SPACE = 'SET_SPACE';
