import React from 'react'
import './SpaceContent.scss'
import ModalTile from '../ModaTile/ModalTile'
import Poi from './SpaceTabs/Poi'
import Info from './SpaceTabs/Info'
import Share from './SpaceTabs/Share'
import Conference from './SpaceTabs/Conference'
import { ReactComponent as Back } from '../../assets/icon/arrow-left.svg'
const SpaceContent = ({
  content,
  hideMenu,
  space,
  point,
  matterport,
  setPoint,
  changeContent,
  setRemoveActiveLink,
  hideMenuMobile
}) => {
  const removeActive = () => {
    changeContent(false)
    setRemoveActiveLink(true)
  }
  const renderContent = (type) => {
    if (hideMenu) return <></>
    switch (type) {
      case 'Info':
        return <Info
          back={removeActive}
          space={space}
        />
      case 'Location':
        return <></>
      case 'Pois':
        return <Poi
          back={removeActive}
          hideMenuMobile={hideMenuMobile}
          matterport={matterport}
          setPoint={setPoint}
          point={point} />
      case '3D':
        return <></>
      case 'Measurements':
        return <></>
      case 'Tiles':
        return <ModalTile
          back={removeActive}
          hideMenuMobile={hideMenuMobile}
          matterport={matterport}
          space={space} />
      case 'Conference call':
        return <Conference
          space={space}
          back={removeActive} />
      case 'Share':
        return <Share
          back={removeActive}
          matterport={matterport}
          space={space} />
      default:
        return <></>
    }
  }
  return (
    <>
      {renderContent(content?.text)}
    </>
  )
}

export default SpaceContent