import React, { useState, useEffect, useRef } from 'react';
// import { useSelector } from 'react-redux';
// import hexRgb from 'hex-rgb';
// import rgbHex from 'rgb-hex';
import { CustomButton } from '../CustomButton/CustomButton';
import './modal.scss';

export const CustomModal = ({
  children,
  title,
  subtitle,
  close,
  cancelBtn,
  submitBtn,
  submit,
  disabled,
  isCancelBtn = true,
  isSubmitBtn = true,
  closeWithoutHide = false,
}) => {
  const [animation, setAnimation] = useState(false);
  const ref = useRef();
  // const color = useSelector(state => state.profile.color);
  // const closeBtnBgColorRgb = hexRgb(color, { format: 'array' });
  // const closeBtnBgColor =
  //   '#' +
  //   rgbHex(
  //     closeBtnBgColorRgb[0] + 50 > 255 ? 255 : closeBtnBgColorRgb[0] + 50,
  //     closeBtnBgColorRgb[1] + 50 > 255 ? 255 : closeBtnBgColorRgb[1] + 50,
  //     closeBtnBgColorRgb[2] + 50 > 255 ? 255 : closeBtnBgColorRgb[2] + 50,
  //     closeBtnBgColorRgb[3]
  //   );

  useEffect(() => {
    setTimeout(() => {
      setAnimation(true);
    }, 5);
  }, []);

  useEffect(() => {
    const handler = e => {
      if (e.key === 'Enter') submit();
    };

    window.addEventListener('keydown', handler);
    return () => window.removeEventListener('keydown', handler);
  });

  const handleClose = () => {
    if (closeWithoutHide) {
      setTimeout(() => {
        close();
      }, 500);
    } else {
      setAnimation(false);
      setTimeout(() => {
        if (ref && ref.current) ref.current.style.display = 'none';
        close();
      }, 500);
    }
  };

  return (
    <div
      className={`custom-modal ${animation ? 'custom-modal_animated' : ''}`}
      ref={ref}
    >
      <div className="custom-modal__background" onClick={handleClose} />
      <div
        className={`custom-modal__container ${
          animation ? 'custom-modal__container_animated' : ''
        }`}
      >
        <div
          className="custom-modal__header"
          // style={{ backgroundColor: color }}
        >
          <div>
            <div className="custom-modal__title">{title}</div>
            {subtitle && (
              <div className="custom-modal__subtitle">{subtitle}</div>
            )}
          </div>
          <div
            className="custom-modal__close-btn"
            onClick={handleClose}
            // style={{ backgroundColor: closeBtnBgColor }}
          >
            &#10006;
          </div>
        </div>
        <div className="custom-modal__main">{children}</div>

        <div className="custom-modal__footer">
          {isCancelBtn && (
            <div className="custom-modal__cancel-btn">
              <CustomButton
                name={cancelBtn || 'Cancel'}
                variant="grey"
                onClick={e => {
                  e.preventDefault();
                  handleClose();
                }}
              />
            </div>
          )}

          {isSubmitBtn && (
            <div className="custom-modal__submit-btn">
              <CustomButton
                variant="green"
                name={submitBtn || 'Save'}
                onClick={e => {
                  e.preventDefault();
                  submit();
                }}
                disabled={disabled || false}
                type="submit"
              />
            </div>
          )}
        </div>

        <div className="custom-modal__spacer" />
      </div>
    </div>
  );
};
