import service from '../service'

export const login = ( id, email, password) => {
  return service.post(`showcase/${id}/login`, {
    email,
    password,
  });
};

export const logout = (id) => {
  return service.post(`showcase/${id}/logout`);
};

export const createPasswordFE = (data) => {
  return service.post('auth/change-password-feuser',{...data})
}
