import React, { useEffect, useReducer, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { arrayItem } from "../../constants/header";
import classNames from "classnames";
import { styled } from "@mui/material/styles";
import "./Navbar.scss";
import { ReactComponent as Avatar } from "../../assets/icon/user.svg";
import { CSSTransition } from "react-transition-group";
import { ReactComponent as Arrow } from "../../assets/icon/arrow.svg";
import { CustomInput } from "../CustomInput/CustomInput";
import { CustomButton } from "../CustomButton/CustomButton";
import {
  formsReducer,
  onFocusOut,
  onInputChange,
  updateFormAction,
  validateForm,
} from "../../helpers/formUtils";
import { useDispatch, useSelector } from "react-redux";
import {
  hideLoaderAction,
  showLoaderAction,
  showSimpleModalAction,
} from "../../redux/actions";
import { login, logout } from "../../crud/auth/auth";
import { useClickOutside } from "../../hooks/useClickOutside";
import { useParams } from "react-router-dom";
import { ReactComponent as LogOutIcon } from "../../assets/icon/logout.svg";
import { ReactComponent as Back } from "../../assets/icon/arrow-left.svg";
import { toCamelCase } from "../../helpers/toCamelCase";

const MenuLink = styled("div", {
  shouldForwardProp: (prop) => prop !== "color",
})(({ color }) => ({
  "&:hover": {
    ".menu-info": {
      color,
    },
    svg: {
      fill: color,
    },
  },
  "&.menu-link__active": {
    ".menu-info": {
      color,
    },
    svg: {
      fill: color,
    },
  },
}));

const initialState = {
  email: { value: "", touched: false, hasError: true, error: "" },
  password: { value: "", touched: false, hasError: true, error: "" },
  isFormValid: false,
};

const NavBar = ({
  setActive,
  hideMenu,
  setUpdateSpace,
  space,
  activeContent,
  removeActiveLink,
  setRemoveActiveLink,
  show,
  setShow,
}) => {
  const [link, setLink] = useState(arrayItem);
  const [showLoggin, setShowLoggin] = useState(false);
  const [isLoggin, setIsLoggin] = useState(false);
  const [email, setEmail] = useState(null);
  const [formData, setFormData] = useReducer(formsReducer, initialState);
  const [variantError, setVariantError] = useState("aside-left");
  const [isTilesStart, setIsTilesStart] = useState(false);
  const dispatch = useDispatch();
  const getAcces = localStorage.getItem("akroton_access_token");
  const modalLog = useRef(null);
  const { id } = useParams();
  const { t } = useTranslation();
  const color = useSelector((state) => state.profile.color);

  useClickOutside(modalLog, () => {
    let bodyWidth = document.body.clientWidth;
    if (showLoggin && bodyWidth > 992) {
      setShowLoggin(false);
    }
  });

  //What need when page First time load
  useEffect(() => {
    localStorage.removeItem("akroton_access_token");
    setIsLoggin(false);
  }, []);

  //remove activeLink
  useEffect(() => {
    if (removeActiveLink) {
      let removeActive = link?.map((li) => {
        li.isActive = false;
        return li;
      });
      setLink(removeActive);
      setRemoveActiveLink(false);
    }
  }, [removeActiveLink]);

  useEffect(() => {
    if (!getAcces) {
      localStorage.removeItem("email");
      setEmail(null);
      setIsLoggin(false);
    }
  }, [getAcces]);

  //When its mobile hide navbar default
  useEffect(() => {
    let width = document.body.clientWidth;
    if (width <= 992) {
      setShow(true);
      hideMenu(true);
      setVariantError("bottom");
    }
  }, []);

  const handleClose = () => {
    setShow((prev) => !prev);
    hideMenu((prev) => !prev);
    //If width 992 px remove active content
    let bodyWidth = document.body.clientWidth;
    if (
      show === true &&
      bodyWidth <= 992 &&
      (activeContent?.text === "Measurements" ||
        activeContent?.text === "3D" ||
        activeContent?.text === "Location")
    ) {
      setActive(false);
    }
  };

  const showLogginMenu = (e) => {
    e.stopPropagation();
    setShowLoggin((prev) => !prev);
  };

  const changeLink = (e, item) => {
    let updateNav = link.map((menu) =>
      menu.text === item.text
        ? { ...menu, isActive: !menu.isActive }
        : { ...menu, isActive: false }
    );
    //for Location create google mapls link
    if (item?.text === "Location" && space?.address) {
      let builder = `https://www.google.com/maps/search/?api=1&query=${space?.address}`;
      let createLink = document.createElement("a");
      createLink.setAttribute("target", "_blank");
      createLink.setAttribute("href", builder);
      //or any other extension
      document.body.appendChild(createLink);
      createLink.click();
      document.body.removeChild(createLink);
    } else {
    }
    let bodyWidth = document.body.clientWidth;
    setLink(updateNav);
    item?.isActive ? setActive(false) : setActive(item);
    if (
      bodyWidth <= 992 &&
      (item?.text === "Location" ||
        item?.text === "3D" ||
        item?.text === "Measurements")
    ) {
      handleClose();
    }
  };

  //log
  const handleLoggin = () => {
    if (validateForm(formData, setFormData)) {
      dispatch(showLoaderAction());
      login(id, formData.email.value, formData.password.value)
        .then((res) => {
          localStorage.setItem("akroton_access_token", res.data.accessToken);
          //localStorage.setItem('email', formData.email.value);
          setEmail(formData.email.value);
          setIsLoggin(true);
          setShowLoggin(false);
          dispatch(hideLoaderAction());
          setUpdateSpace((prev) => !prev);
          // dispatch(showSimpleModalAction({
          //   title: t('loginSuccess.success'),
          //   text: t('loginSuccess.successfullyLoggedIn'),
          // }))
        })
        .catch((err) => {
          dispatch(hideLoaderAction());
          let message = err?.response?.data?.message;
          if (message) {
            setFormData(
              updateFormAction({
                ...formData.email,
                name: "email",
                touched: true,
                hasError: true,
                error: t("notifications.incorrectEmailOrPassword"),
                isFormValid: false,
              })
            );
            setFormData(
              updateFormAction({
                ...formData.password,
                name: "password",
                touched: true,
                hasError: true,
                error: "",
                isFormValid: false,
              })
            );
          }
        });
    }
  };

  const logOut = () => {
    dispatch(showLoaderAction());
    logout(id).then(() => {
      localStorage.removeItem("akroton_access_token");
      dispatch(hideLoaderAction());
      setIsLoggin(false);
      setEmail(null);
      setUpdateSpace((prev) => !prev);
    });
  };

  // Settings menu for space tile
  useEffect(() => {
    //Delete items from header
    if (space !== null) {
      const canRemove = new Map();
      canRemove.set("Tiles", space?.tileSettings?.showTileMenu);
      canRemove.set("Share", space?.visibility?.shareButtonVisibility);
      canRemove.set("VR", space?.visibility?.vrButtonVisibility);
      canRemove.set("Pois", space?.visibility?.poisVisibility);
      canRemove.set(
        "Conference call",
        space?.visibility?.conferenceCallVisibility
      );
      let needDelete = [...canRemove]
        .filter((item) => item[1] === false)
        .map((nav) => nav[0]);
      let filterHeaderLink = link.filter((head) => {
        if (needDelete.includes(head.text)) {
          return false;
        } else {
          return true;
        }
      });
      setLink(filterHeaderLink);
      setIsTilesStart(true);
    }
  }, [space]);

  useEffect(() => {
    if (space !== null && isTilesStart) {
      let sortMenu = link?.map((menu) => {
        if (
          menu.text === "Tiles" &&
          space?.tileSettings?.showAtStart &&
          space?.tileSettings?.showTileMenu
        ) {
          menu.isActive = true;
          setActive({
            isActive: true,
            text: "Tiles",
          });
        }
        return menu;
      });
      setLink(sortMenu);
    }
  }, [isTilesStart]);

  return (
    <nav
      className={classNames({
        "main-nav": true,
        "main-nav--position": !show,
      })}
    >
      <CSSTransition in={show} timeout={20} className="main-nav__menu">
        <div>
          <button
            className={classNames("main-nav__collapse", {
              "main-nav__collapse--hidden": !show && activeContent,
            })}
            onClick={handleClose}
          >
            <span
              className={classNames({
                "main-nav__burger": true,
              })}
            ></span>
            <span
              className={classNames({
                "main-nav__burger": true,
              })}
            ></span>
            <span
              className={classNames({
                "main-nav__burger": true,
              })}
            ></span>
            <Arrow
              className={classNames({
                rotate: !show,
                collapse: true,
              })}
            />
          </button>
          <div
            className={classNames({
              "main-nav__visible": true,
              "main-nav__visible--hide": show,
              "main-nav__visible--hide_content": activeContent?.text,
              "main-nav__visible--log": showLoggin,
            })}
          >
            {link.map((li, index) => {
              return (
                <MenuLink
                  onClick={(e) => changeLink(e, li)}
                  key={`head${index}`}
                  className={classNames({
                    "menu-link__active": li.isActive,
                    "menu-link": true,
                  })}
                  color={color}
                >
                  {li.icon}
                  <span className="menu-info">
                    {t(`menuItems.${toCamelCase(li.text)}`)}
                  </span>
                </MenuLink>
              );
            })}
            <div
              onClick={() => {
                setShowLoggin((prev) => !prev);
              }}
              className="menu-link--hide_large"
            >
              <span className="menu-info">
                {t(isLoggin ? "logout" : "login")}
              </span>
            </div>
          </div>
        </div>
      </CSSTransition>
      <div onClick={showLogginMenu} className="profile">
        <p className="profile-avatar">
          <Avatar />
        </p>
      </div>
      {showLoggin && (
        <div
          ref={modalLog}
          className={classNames({
            "profile-mini-modal": true,
            "profile-mini-modal--hide": showLoggin && show,
          })}
        >
          <div
            onClick={() => {
              setActive(false);
              setShowLoggin(false);
            }}
            className="profile-back"
          >
            <Back />
            <p>Back to Menu</p>
          </div>
          {isLoggin ? (
            <>
              <div className="profile-mini-modal__email">{email}</div>
              <div className="profile-mini-modal__logout" onClick={logOut}>
                <LogOutIcon />
                <span>{t("logOut")}</span>
              </div>
            </>
          ) : (
            <>
              <div className="profile-mini-modal__int_wrapper">
                <CustomInput
                  variantError={variantError}
                  type="email"
                  placeholder={t("loginPopup.email")}
                  formData={formData.email}
                  onChange={(e) =>
                    onInputChange(
                      "email",
                      e.target.value,
                      setFormData,
                      formData
                    )
                  }
                  onBlur={(e) =>
                    onFocusOut("email", e.target.value, setFormData, formData)
                  }
                />
                <CustomInput
                  variantError={variantError}
                  type="password"
                  placeholder={t("loginPopup.password")}
                  formData={formData.password}
                  onChange={(e) =>
                    onInputChange(
                      "password",
                      e.target.value,
                      setFormData,
                      formData
                    )
                  }
                  onBlur={(e) =>
                    onFocusOut(
                      "password",
                      e.target.value,
                      setFormData,
                      formData
                    )
                  }
                />
              </div>
              <CustomButton name={t("login")} onClick={handleLoggin} />
            </>
          )}
        </div>
      )}
    </nav>
  );
};

export default NavBar;
