import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { getSpaceByPrettyUrl } from '../crud/showcase/showcase'
import NavBar from '../components/Havbar/NavBar'
import Space from '../components/Space/Space'
import './MainPage.scss'
import SpaceContent from '../components/SpaceContent/SpaceContent'
import { useDispatch, useSelector } from 'react-redux'
import { hideLoaderAction, setConfigs, showLoaderAction, setSpace } from '../redux/actions'

const PrettyUrlPage = () => {
  const dispatch = useDispatch()
  const [activeContent, setActiveContent] = useState(null);
  const [hideMenu, setHideMenu] = useState(false);
  const [point, setPoint] = useState([])
  const [updateSpace, setUpdateSpace] = useState(false)
  const [matterport, setMatterport] = useState(null)
  const [removeActiveLink, setRemoveActiveLink] = useState(false)
  const [show, setShow] = useState(false);
  const { i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const space = useSelector(state => state.profile.space);

  useEffect(() => {
    const prettyUrl =
      location.pathname && location.pathname.charAt(0) === '/'
        ? location.pathname.slice(1) + location.search
        : location.pathname + location.search;
    if (!prettyUrl.includes('showcase/')) {
      dispatch(showLoaderAction())
      getSpaceByPrettyUrl(prettyUrl).then(res => {
        const id = res.data.id;
        if (id) {
          dispatch(setSpace(res.data));
          dispatch(setConfigs(res.data?.configs ?? {}));
        } else {
          navigate('/404');
        }
      }).catch(() => {
        navigate('/404');
      }).finally(() => {
        dispatch(hideLoaderAction())
      })
    }
  }, [dispatch, location.pathname, location.search, navigate, updateSpace])

  const hideMenuMobile = () => {
    const bodyWidth = document.body.clientWidth;
    if (bodyWidth > 992) return
    setShow(prev => !prev);
    setHideMenu(prev => !prev);
    if (show === true &&
      bodyWidth <= 992 &&
      (activeContent?.text === 'Measurements' ||
        activeContent?.text === '3D' ||
        activeContent?.text === 'Location'
      )) {
      setActiveContent(false)
    }
  }

  useEffect(() => {
    i18n.changeLanguage(space?.lang ?? 'en');
  }, [i18n, space]);

  return (
    <div className='main'>
      <NavBar
        space={space}
        setRemoveActiveLink={setRemoveActiveLink}
        removeActiveLink={removeActiveLink}
        setUpdateSpace={setUpdateSpace}
        setActive={setActiveContent}
        activeContent={activeContent}
        show={show}
        setShow={setShow}
        hideMenu={setHideMenu} />
      <SpaceContent
        space={space}
        setRemoveActiveLink={setRemoveActiveLink}
        content={activeContent}
        changeContent={setActiveContent}
        point={point}
        setPoint={setPoint}
        matterport={matterport}
        hideMenuMobile={hideMenuMobile}
        hideMenu={hideMenu} />
      <Space
        space={space}
        point={setPoint}
        menu={hideMenu}
        setMatterport={setMatterport}
        changeContent={setActiveContent}
        setRemoveActiveLink={setRemoveActiveLink}
        content={activeContent} />
    </div>
  )
}

export default PrettyUrlPage