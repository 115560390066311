import React from 'react';
import check from '../../assets/icon/check.png';
import './checkbox.scss';
import classNames from 'classnames'

export const CustomCheckbox = ({ label, checked, onChange , onLabelClick, isBoldLabel=false  }) => {
    return (
        <div className="custom-checkbox">
            <div
                className="checkbox"
                onClick={onChange}
            >
                {checked && <img src={check} alt="checked" />}
            </div>
            <div
                className={classNames("label pointer",{
                  'label--on': onLabelClick,
                  'label--bold':isBoldLabel
                })}
                onClick={onLabelClick ? onLabelClick : null}
            >
                {label}
            </div>
        </div>
    )
};
