import React from 'react';
import { useSelector } from 'react-redux';
import './button.scss';

export const CustomButton = ({
  variant,
  name,
  onClick,
  disabled,
  typeSize,
  type = '',
  onMouseOver,
  onMouseLeave,
}) => {
  const color = useSelector(state => state.profile.color);
  const buttonClassnames = `button
  ${disabled ? 'button_disabled' : ''}
  ${variant || 'green'}
`;

  return (
    <div className={`custom-button ${typeSize ? `${typeSize}` : ''}`}>
      <button
        type={type}
        onClick={onClick ? onClick : () => {}}
        disabled={disabled || false}
        className={buttonClassnames}
        onMouseOver={onMouseOver && onMouseOver}
        onMouseLeave={onMouseLeave && onMouseLeave}
        style={
          buttonClassnames.includes('green') ? { backgroundColor: color } : {}
        }
      >
        {name}
      </button>
    </div>
  );
};
