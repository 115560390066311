/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import classNames from 'classnames'
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import '../SpaceContent.scss'
import { ReactComponent as Skype } from '../../../assets/icon/skype.svg'
import { ReactComponent as Meet } from '../../../assets/icon/meet.svg'
import { ReactComponent as Zoom } from '../../../assets/icon/zoom.svg'
import { ReactComponent as Teams } from '../../../assets/icon/teams.svg'
import { ReactComponent as Back } from '../../../assets/icon/arrow-left.svg'

const SpaceConferenceLink = styled('a', {
  shouldForwardProp: prop => prop !== 'color',
})(({ color }) => ({
  '&:hover': {
    color,
    transform: 'scale(1.1)'
  }
}));

const Conference = ({ space, back }) => {
  const color = useSelector(state => state.profile.color);

  return (
    <div
      className={classNames({
        'space-conference': true,
        'space-conference--position': !space?.tileSettings?.showTileMenu && (space !== null),
        'space-conference--right_position': !space?.visibility?.poisVisibility && (space !== null)
      })}>
      <div
        onClick={back}
        className='profile-back profile-back--margin_none'>
        <Back />
        <p>Back to Menu</p>
      </div>
      <SpaceConferenceLink className='space-conference__link'
        href='https://meet.google.com/'
        target='_blank' color={color}>
        <div className='space-conference__wrap-icon'>
          <Meet />
        </div>
        Google Meet
      </SpaceConferenceLink>
      <SpaceConferenceLink className='space-conference__link'
        href='https://zoom.us/'
        target='_blank' color={color}>
        <div className='space-conference__wrap-icon'>
          <Zoom />
        </div>
        Zoom
      </SpaceConferenceLink>
      <SpaceConferenceLink className='space-conference__link'
        href='https://www.microsoft.com/en-ww/microsoft-teams/log-in'
        target='_blank' color={color}>
        <div className='space-conference__wrap-icon'>
          <Teams />
        </div>
        Microsoft Teams
      </SpaceConferenceLink>
      <SpaceConferenceLink className='space-conference__link'
        href='https://www.skype.com/en/'
        target='_blank' color={color}>
        <div className='space-conference__wrap-icon'>
          <Skype />
        </div>
        Skype
      </SpaceConferenceLink>
    </div>
  )
}

export default Conference