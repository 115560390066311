import {
    SHOW_LOADER,
    HIDE_LOADER,
    SHOW_ERROR_MODAL,
    HIDE_ERROR_MODAL,
    SET_POI,
    SET_POI_CHECKED,
} from '../constantTypes'

const initialState = {
    loader: false,
    simpleModal: { isModal: false, title: '', text: '' },
    poi: [],
    poiChecked :{
        'empty': []
    }
};

export const helperReducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_LOADER:
            return {
                ...state,
                loader: true
            }
        case HIDE_LOADER:
            return {
                ...state,
                loader: false
            }
        case SHOW_ERROR_MODAL:
            return {
                ...state,
                simpleModal: {
                    isModal: true,
                    title: action.payload.title,
                    text: action.payload.text,
                }
            }
        case HIDE_ERROR_MODAL:
            return {
                ...state,
                simpleModal: {
                    isModal: false,
                    title: '',
                    text: '',
                }
            }
        case SET_POI:
            return {
                ...state,
                poi: action.payload
            }
        case SET_POI_CHECKED:
            return {
                ...state,
                poiChecked: action.payload
            }
        default: return state
    }
};
