import React from 'react'
import './ModalPoint.scss'
import {ReactComponent as Default} from '../../assets/icon/icon.svg'
import classNames from 'classnames'

const ModalPoint = ({poi, setPoi, hideAdd,hide}) => {

  const sanitizedData = () => ({
    __html: poi?.description
  })

  const handleClose = () =>{
    setPoi(null)
  }

  const getWidth = () =>{
    if(poi?.modalSize?.width){
      return poi?.modalSize?.width
    }
    return '314px'
  }
  const getHeight = () =>{
    if(poi?.modalSize?.height){
      return poi?.modalSize?.height
    }
    return 'auto'
  }
  const getOpacity = () =>{
    if(poi?.opacity){
      return (+poi?.opacity / 100)
    }
    return '1'
  }
  return (
    <div style={{width:getWidth(),height:getHeight(),opacity:getOpacity()}}
         className={classNames({
           'point':true,
           'point--shadow':hide,
         })}>
      <div className='point--resible'>
        {hideAdd && <button onClick={handleClose}
                            className='point__close'>&#x2716;</button>}
        <div className='point__head'>
          {poi?.icon ?
            <img className='point__icon' src={poi?.icon}/>
            :<Default className='point__default-icon' style={{fill:poi?.backgroundColor}}/>
          }
          <h3 className='point__title'>{poi?.name}</h3>
        </div>
        <div className='point__main'
             dangerouslySetInnerHTML={sanitizedData()}
        >
        </div>
      </div>
    </div>
  )
}

export default ModalPoint