import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import '../SpaceContent.scss'
import { SimpleInput } from '../../CustomInput/SimpleInput'
import { ReactComponent as Facebook } from '../../../assets/icon/facebook-f.svg'
import { ReactComponent as Twitter } from '../../../assets/icon/twitter.svg'
import { ReactComponent as Linkedin } from '../../../assets/icon/linkedin-in.svg'
import { ReactComponent as Pinterest } from '../../../assets/icon/pinterest-p.svg'
import { ReactComponent as Mail } from '../../../assets/icon/mail.svg'
import { ReactComponent as Back } from '../../../assets/icon/arrow-left.svg'

const SpaceShareBtn = styled('button', {
  shouldForwardProp: prop => prop !== 'color',
})(({ color }) => ({
  '&:after': {
    background: color
  }
}));

const Share = ({ space, matterport, back }) => {
  const value = window.location.href;
  const [shareState, setShareState] = useState({
    'website': false,
    'embed': false,
    'matterport': false,
    'matterportValue': ''
  });
  const color = useSelector(state => state.profile.color);

  useEffect(() => {
    //If we has sdk model
    async function createDeepLink() {
      await matterport.Link.setShareLinkPolicy(matterport.Link.CreationPolicy.REFERRER);
      let link = await matterport.Link.createDeepLink();
      let getParams = new URL(link);
      const searchParams = new URLSearchParams(getParams.search);
      let sm = searchParams.get("sm");
      let sr = searchParams.get("sr");
      let sp = searchParams.get("sp");
      let sf = searchParams.get("sf");
      let ss = searchParams.get("ss");
      const createParams = () => {
        let paramsString = ''
        if (sm !== null) {
          paramsString = paramsString + `&sm=${sm}`
        } else { }
        if (sr !== null) {
          paramsString = paramsString + `&sr=${sr}`
        } else { }
        if (sp !== null) {
          paramsString = paramsString + `&sp=${sp}`
        } else { }
        if (sf !== null) {
          paramsString = paramsString + `&sf=${sf}`
        } else { }
        if (ss !== null) {
          paramsString = paramsString + `&ss=${ss}`
        } else { }
        return paramsString
      }
      let params = createParams();
      let searchSpace = window.location.search.split('&')[0];
      let createDeep = window.location.origin + window.location.pathname + searchSpace + params
      setShareState(prev => ({ ...prev, 'matterportValue': createDeep }))
    }
    if (matterport !== null) {
      createDeepLink()
    }
  }, [matterport])
  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }
  const handleCopyClick = async (e, type) => {
    // Asynchronously call copyTextToClipboard
    let value
    switch (type) {
      case 'website':
        value = window.location.href;
        break;
      case 'embed':
        value = `<iframe src=${space?.url} name="myIFrame"  frameborder='0' allowfullscreen allow='xr-spatial-tracking' width='853' height='480'></iframe>`
        break;
      case 'matterport':
        await matterport.Link.setShareLinkPolicy(matterport.Link.CreationPolicy.REFERRER);
        let link = await matterport.Link.createDeepLink();
        //Create from link object Url and get from object params
        let getParams = new URL(link);
        //Iterator params to filter 2 params with curr location on camera need to deepLink
        const searchParams = new URLSearchParams(getParams.search);
        let sm = searchParams.get("sm");
        let sr = searchParams.get("sr");
        let sp = searchParams.get("sp");
        let sf = searchParams.get("sf");
        let ss = searchParams.get("ss");
        const createParams = () => {
          let paramsString = ''
          if (sm !== null) {
            paramsString = paramsString + `&sm=${sm}`
          } else { }
          if (sr !== null) {
            paramsString = paramsString + `&sr=${sr}`
          } else { }
          if (sp !== null) {
            paramsString = paramsString + `&sp=${sp}`
          } else { }
          if (sf !== null) {
            paramsString = paramsString + `&sf=${sf}`
          } else { }
          if (ss !== null) {
            paramsString = paramsString + `&ss=${ss}`
          } else { }
          return paramsString
        }
        let params = createParams();
        let searchSpace = window.location.search.split('&')[0];
        let createDeep = window.location.origin + window.location.pathname + searchSpace + params
        setShareState(prev => ({ ...prev, 'matterportValue': createDeep }))
        value = createDeep
        break;
      default:
        value = ''
    }
    copyTextToClipboard(value)
      .then(() => {
        // If successful, update the isCopied state value
        setShareState(prev => ({ ...prev, [type]: true }));
        setTimeout(() => {
          setShareState(prev => ({ ...prev, [type]: false }));
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  return (
    <div className={classNames({
      'space-share': true,
      'space-share--position_tile': !space?.tileSettings?.showTileMenu && (space !== null),
      'space-share--position_conference': !space?.visibility?.conferenceCallVisibility && (space !== null),
      'space-share--all': !space?.visibility?.conferenceCallVisibility && !space?.tileSettings?.showTileMenu && (space !== null),
      'space-share--poi': !space?.visibility?.poisVisibility && (space !== null),
      'space-share--poi_tiles': !space?.visibility?.poisVisibility && space?.tileSettings?.showTileMenu && (space !== null),
      'space-share--poi_tiles_conf': !space?.visibility?.poisVisibility && space?.tileSettings?.showTileMenu && space?.visibility?.conferenceCallVisibility && (space !== null),
    })}>
      <div
        onClick={back}
        className='profile-back'>
        <Back />
        <p>Back to Menu</p>
      </div>
      <div className='space-share__social'>
        <div
          onClick={() => {
            const windowFeatures = "left=100,top=100,width=520,height=520";
            const handle = window.open(`http://www.facebook.com/sharer/sharer.php?u=${value}&title=title`, "facebookWindow", windowFeatures);
          }}
          className='space-share__icon'>
          <Facebook />
        </div>
        <div
          onClick={() => {
            const windowFeatures = "left=100,top=100,width=520,height=520";
            const handle = window.open(`https://twitter.com/intent/tweet?original_referer=${value}&text=space&url=${value}`, "facebookWindow", windowFeatures);
          }}
          className='space-share__icon'>
          <Twitter />
        </div>
        <div
          onClick={() => {
            const windowFeatures = "left=100,top=100,width=520,height=520";
            const handle = window.open(`http://www.linkedin.com/shareArticle?mini=true&url=${value}`, "facebookWindow", windowFeatures);
          }}
          className='space-share__icon'>
          <Linkedin />
        </div>
        <div
          onClick={() => {
            const windowFeatures = "left=100,top=100,width=520,height=520";
            const handle = window.open(`http://pinterest.com/pin/create/bookmarklet/?media=${value}&url=${value}&is_video=false&description=[TITLE]`, "facebookWindow", windowFeatures);
          }}
          className='space-share__icon'>
          <Pinterest />
        </div>
        <a href="mailto:webmaster@example.com" className='space-share__icon'>
          <Mail />
        </a>
      </div>
      <div className='space-share__wrapper'>
        <div className='space-share__hero'>
          <p className='space-share-title'>Link</p>
          <div className='space-share__main'>
            <SimpleInput
              value={value}
              disabled={true}
            />
            <SpaceShareBtn
              onClick={(e) => handleCopyClick(e, 'website')}
              className='space-share__btn'
              color={color}>
              {shareState.website ? 'Copied!' : 'Copy'}
            </SpaceShareBtn>
          </div>
        </div>
        <div className='space-share__hero'>
          <p className='space-share-title'>Deep link</p>
          <div className='space-share__main'>
            <SimpleInput
              value={shareState.matterportValue}
              disabled={true}
            />
            <SpaceShareBtn
              onClick={(e) => handleCopyClick(e, 'matterport')}
              className='space-share__btn' color={color}>
              {shareState.matterport ? 'Copied!' : 'Copy'}
            </SpaceShareBtn>
          </div>
        </div>
        <div className='space-share__hero'>
          <p className='space-share-title'>Embed</p>
          <div className='space-share__main'>
            <SimpleInput
              value={`<iframe src=${space?.url} name="myIFrame" scrolling="auto" width="50%" height="50%" ></iframe>`}
              disabled={true}
            />
            <SpaceShareBtn
              onClick={(e) => handleCopyClick(e, 'embed')}
              className='space-share__btn' color={color}>
              {shareState.embed ? 'Copied!' : 'Copy'}
            </SpaceShareBtn>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Share