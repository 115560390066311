import {ReactComponent as Avatar} from '../assets/icon/avatar.svg'
import {ReactComponent as Grid} from  '../assets/icon/grid.svg'
import {ReactComponent as Cube} from  '../assets/icon/cube.svg'
import {ReactComponent as Poi} from  '../assets/icon/point.svg'
import {ReactComponent as Video} from  '../assets/icon/video.svg'
import {ReactComponent as Share} from  '../assets/icon/share-alt.svg'
import {ReactComponent as Info} from  '../assets/icon/info-circle.svg'
import {ReactComponent as Map} from  '../assets/icon/map-marker-alt.svg'
import {ReactComponent as Rule} from  '../assets/icon/ruler.svg'

 export const arrayItem = [
  {
    text:'Info',
    isActive:false,
    icon:<Info/>
  },
  {
    text:'Location',
    isActive:false,
    icon:<Map/>
  },
  {
    text:'Pois',
    isActive:false,
    icon:<Poi/>
  },
  {
    text:'3D',
    isActive:false,
    icon:<Cube/>
  },
  {
    text:'Measurements',
    isActive:false,
    icon:<Rule/>
  },
  {
    text:'Tiles',
    isActive:false,
    icon:<Grid/>
  },
  {
    text:'Conference call',
    isActive:false,
    icon:<Video/>
  },
  {
    text:'Share',
    isActive:false,
    icon:<Share/>
  },
  {
    text:'VR',
    isActive:false,
    icon:<Avatar/>
  }
]