import {
    SIGN_IN,
    SET_LANGUAGE,
    SET_CONFIGS,
    SET_SPACE,
} from '../constantTypes'

const initialState = {
    language: {
        name: "English",
        value: "en"
    },
    color: '#8DC63F',
    logo: '/static/media/logo.e4295569.svg',
    favicon: '/favicon.ico',
    matterportSdkKey: process.env.REACT_APP_MATTERPORT_SDK_KEY,
    space: null,
};

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case SIGN_IN:
            return { ...state, ...action.payload }
        case SET_LANGUAGE:
            return { ...state, language: action.payload }
        case SET_CONFIGS:
            let { color, favicon, logo, matterport_sdk_key } = action.payload;
            const data = {};
            if (color) data.color = color;
            if (favicon) data.favicon = `${process.env.REACT_APP_API_URL}/${favicon}`;
            if (logo) data.logo = `${process.env.REACT_APP_API_URL}/${logo}`;
            if (matterport_sdk_key && matterport_sdk_key !== 'null') {
                data.matterportSdkKey = matterport_sdk_key;
            }
            return { ...state, ...data }
        case SET_SPACE:
            return { ...state, space: action.payload }
        default: return state;
    }
};
