import React from 'react'
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import '../SpaceContent.scss'
import { ReactComponent as Avatar } from '../../../assets/icon/avatar.svg'
import { ReactComponent as Mail } from '../../../assets/icon/mail.svg'
import { ReactComponent as Location } from '../../../assets/icon/map-marker-alt.svg'
import { ReactComponent as Map } from '../../../assets/icon/globe.svg'
import { ReactComponent as Back } from '../../../assets/icon/arrow-left.svg'
import { ReactComponent as Phone } from '../../../assets/icon/phone.svg'
import { ReactComponent as Readme } from '../../../assets/icon/readme.svg'

const ExternalUrl = styled('a', {
  shouldForwardProp: prop => prop !== 'color',
})(({ color }) => ({
  '&:hover': {
    span: {
      color
    }
  },
}));

const Info = ({ space, back }) => {
  const { t } = useTranslation();
  const color = useSelector(state => state.profile.color);

  return (
    <div className='space-information'>
      <div
        onClick={back}
        className='profile-back profile-back--padding'>
        <Back />
        <p>Back to Menu</p>
      </div>
      <div className='space-column space-information__head'>
        <span className='space-information__header'>
          {`${t('infoMenu.presentedBy')} ${space?.presentedBy}`}
        </span>
        <span className='space-information__place'>{space?.name || '-'}</span>
      </div>
      <div className='space-column space-information__body'>
        {space?.contact?.contactName && <div className='space-information__wrap'>
          <Avatar />
          <span>{space?.contact?.contactName || '-'}</span>
        </div>}
        {space?.contact?.contactEmail && <div className='space-information__wrap'>
          <Mail />
          <span>{space?.contact?.contactEmail || '-'}</span>
        </div>}
        {space?.address && <div className='space-information__wrap'>
          <Location />
          <span>{space?.address?.description || '-'}</span>
        </div>}
        {space?.contact?.contactPhone && <div className='space-information__wrap'>
          <Phone />
          <span>{space?.contact?.contactPhone || '-'}</span>
        </div>}
        {space?.externalUrl && <ExternalUrl
          href={`${space?.externalUrl}`}
          target='_blank'
          className='space-information__wrap' rel="noreferrer" color={color}>
          <Map />
          <span>{space?.externalUrl || '-'}</span>
        </ExternalUrl>}
        {space?.summary && <div className='space-information__wrap'>
          <Readme />
          <span>{space?.summary}</span>
        </div>}
      </div>
    </div>
  )
}

export default Info