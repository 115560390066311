import hexRgb from 'hex-rgb'

const removeAll = async (showcase, poiList, isHide = false) => {
  showcase.Tag.toggleNavControls(false)
  showcase.Tag.toggleSharing(false);
  showcase.Tag.toggleDocking(false);
  let tag = await showcase?.Mattertag.getData()
  tag?.forEach((origMatt => {
    showcase?.Mattertag.remove(origMatt.sid)
  }))
  let poiRender = poiList?.map(async poi => {
    let colorToRgb = hexRgb(poi?.backgroundColor)
    let r = colorToRgb.red / 255
    let g = colorToRgb.green / 255
    let b = colorToRgb.blue / 255
    const tag = {
      label: poi?.name || '',
      description: '',
      anchorPosition: { x: poi.x, y: poi.y, z: poi.z },
      stemVector: poi?.stemVector || { x: 0, y: 0, z: 0 },
      color: { r, g, b },
    }
    let matPoi = await showcase.Mattertag.add([tag])
    poi.matterPortId = matPoi[0]
    await showcase?.Tag.allowAction(matPoi[0], {
      navigating : true,
      opening: true,
    })
    if (poi?.icon) {
      let math = Math.random()?.toFixed(3)
      showcase?.Mattertag.registerIcon(`${matPoi[0]}-${math}`, poi?.icon).then(res => {
        showcase.Mattertag.editIcon(matPoi[0], `${matPoi[0]}-${math}`).then(res => {
        })
      })
    } else {
    }
    if (poi?.enabled) {
      let opacity = poi?.opacity / 100
      showcase.Mattertag.editOpacity(matPoi[0], opacity)
    } else {
      showcase.Mattertag.editOpacity(matPoi[0], 0)
    }
    showcase.Mattertag.editStem(matPoi[0],
      {
        stemVisible: poi?.stemVisible,
        stemHeight : poi?.stemLength
      }
    )
    return poi
  })
  return await Promise.all(poiRender)
}

const fullRemove = async (showcase) => {
  let tag = await showcase?.Mattertag.getData()
  tag?.forEach((origMatt => {
    showcase?.Mattertag.remove(origMatt.sid)
  }))
}

const removeOne = async (showcase, id) => {
  await showcase?.Mattertag.editOpacity(id, 0)
  await showcase.Tag.allowAction(id, {
    navigating : false,
    opening: false,
  })
}

const addTag = async (showcase, id) => {
  await showcase?.Mattertag.editOpacity(id, 1)
  await showcase.Tag.allowAction(id, {
    navigating : true,
    opening: true,
  })
}

const allPrevent = async (showcase) => {
  let tag = await showcase?.Mattertag.getData()
  await Promise.all(tag?.forEach((origMatt => {
    showcase?.Mattertag.editOpacity(origMatt.sid, 0)
    showcase.Tag.allowAction(origMatt.sid, {
      navigating : false,
      opening: false,
    })
  })))
}

const allDisplay = async (showcase) => {
  let tag = await showcase?.Mattertag.getData()
  await Promise.all(tag?.forEach((origMatt => {
    showcase?.Mattertag.editOpacity(origMatt.sid, 1)
    showcase.Tag.allowAction(origMatt.sid, {
      navigating : true,
      opening: true,
    })
  })))
}


export { removeAll, fullRemove, removeOne, addTag, allPrevent, allDisplay }
