import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import '../SpaceContent.scss'
import { SimpleInput } from '../../CustomInput/SimpleInput'
import { CustomCheckbox } from '../../CustomCheckbox/CustomCheckbox'
import { addTag, allDisplay, allPrevent, removeOne } from '../../../helpers/poiFunction'
import { ReactComponent as Back } from '../../../assets/icon/arrow-left.svg'
import { ReactComponent as Pois } from '../../../assets/icon/icon.svg'
import { useDispatch, useSelector } from 'react-redux'
import { setPoi, setPoiChecked } from '../../../redux/actions'

const Poi = ({ point, matterport, setPoint, back, hideMenuMobile }) => {
  const [allCheck, setAllCheck] = useState(true)
  const [categoryCheck, setCategoryCheck] = useState({})
  const dispatch = useDispatch()
  const sortPoint = useSelector(state => state.helpers.poiChecked)
  let copySortPoint = useRef(null)
  const [search, setSearch] = useState('')
  const { t } = useTranslation();

  // function to sort and group by category
  const sortByCategoies = (point) => {
    const boxObject = {
      'empty': []
    }
    let isEmpty = point.length
    if (isEmpty) {
      point.map(poi => {
        poi.checked = poi.isShowOnMinimap
        let categoriesPoi = poi.categories
        let isEmptyPoi = categoriesPoi.length
        if (isEmptyPoi) {
          categoriesPoi.map(category => {
            let isExist = boxObject.hasOwnProperty(`${category.title}`)
            if (isExist) {
              boxObject[`${category.title}`].push({ ...poi })
            } else {
              boxObject[`${category.title}`] = [{ ...poi }]
            }
          })
        } else {
          boxObject.empty.push({ ...poi })
        }
      })
    }
    // setCategoryCheck
    let checkbox = {}
    for (const field in boxObject) {
      checkbox[field] = true
    }
    setCategoryCheck(checkbox)
    dispatch(setPoiChecked(boxObject))
    copySortPoint.current = boxObject
  }
  // every time when change point need to grouped by categories
  useEffect(() => {
    sortByCategoies(point)
  }, [point])

  useEffect(() => {
    let allItems = []
    Object.keys(sortPoint).map(objKey => {
      allItems.push(...sortPoint[objKey])
    }
    )
    if (!allItems.length) return
    let updatePoi = point?.map(poi => {
      let findInAll = allItems?.find(all => all.id === poi.id)
      poi.isShowOnMinimap = !!findInAll?.checked;
      return poi
    });
    dispatch(setPoi(updatePoi))
  }, [sortPoint])

  const handleSearch = (e) => {
    let value = e.target.value
    if (value == '') {
      dispatch(setPoiChecked(copySortPoint.current))
    } else {
      let boxValue = {}
      let filter = Object.keys(copySortPoint.current).map((key) => {
        let filterArray = copySortPoint.current[key].filter(poi => poi?.name?.toLowerCase()
          .includes(value.toLowerCase()))
        if (key !== 'empty' && key.toLowerCase().includes(value.toLowerCase())) {
          filterArray = copySortPoint.current[key]
        }
        boxValue[key] = filterArray
      })
      dispatch(setPoiChecked(boxValue))
    }
    setSearch(value)
  }

  const handleAllCheck = async (e) => {
    setAllCheck(prev => !prev)
    let boxValue = {}
    const getObj = search ? sortPoint : copySortPoint.current;
    let filter = Object.keys(getObj).map((key) => {
      let filterArray = getObj[key].map(poi => {
        poi.checked = allCheck ? false : true
        return poi
      })
      boxValue[key] = filterArray
    })
    let checkbox = {}
    for (const field in boxValue) {
      let value = !categoryCheck[field];
      checkbox[field] = !allCheck
    }
    setCategoryCheck(checkbox)
    dispatch(setPoiChecked(boxValue))
    if (allCheck) {
      allPrevent(matterport)
    } else {
      allDisplay(matterport)
    }
  }

  const onLabelClick = async (e, id) => {
    const bodyWidth = document.body.clientWidth;
    if (bodyWidth > 992) {
      back()
    } else {
      hideMenuMobile()
    }
    try {
      await matterport.Mattertag.navigateToTag(id, matterport.Mattertag.Transition.FLY)
    } catch (e) {
      debugger
    }
  }

  const onCategoryChange = (type) => {
    let find = copySortPoint.current[type];
    let valueForPoi = categoryCheck[type];
    find.forEach(item => onCheckPoi(null, item.id, !valueForPoi))
    const value = !categoryCheck[type]
    setCategoryCheck(prev => ({ ...prev, [type]: value }))
  }

  const onCheckPoi = (e, id, value) => {
    let boxValue = {};
    const getObj = search ? sortPoint : copySortPoint.current;
    let filter = Object.keys(getObj).map((key) => {
      let filterArray = getObj[key].map((poi) => {
        let poiId = poi.id === id
        if (poiId) {
          if (poi.checked) {
            removeOne(matterport, poi.matterPortId)
          } else {
            addTag(matterport, poi.matterPortId)
          }
          poi.checked = value ? value : !poi.checked
          return poi
        } else {
          return poi
        }
      })
      boxValue[key] = filterArray
    })
    let isAllChecked = Object.keys(copySortPoint.current).every((key) => {
      return copySortPoint.current[key].every(item => item.checked === true) === true
    })
    let categoryCheck = {}
    for (const field in boxValue) {
      categoryCheck[field] = boxValue[field].every(item => item.checked === true) === true
    }
    setCategoryCheck(categoryCheck)
    isAllChecked ? setAllCheck(true) : setAllCheck(false)
    dispatch(setPoiChecked(boxValue))
  }

  const renderLabelWithIcon = (item) => {
    if (item.icon) {
      return <p className='space-poi__check-container'>
        <img
          className='space-poi__icon' alt={item.name}
          src={item.icon} />
        {item.name || 'no name'}
      </p>
    } else {
      return <p className='space-poi__check-container'>
        <Pois style={{ fill: item.backgroundColor }} className='space-poi__icon' />
        {item.name || 'no name'}
      </p>
    }
  }

  return (
    <div className='space-poi'>
      <div
        onClick={back}
        className='profile-back profile-back--padding'>
        <Back />
        <p>Back to Menu</p>
      </div>
      <div className='space-poi__head'>
        <div className='space-poi__search'>
          <SimpleInput
            value={search}
            onChange={handleSearch}
            isSearch={true}
            placeholder={t('poiMenu.search')}
          />
        </div>
        <div className='space-poi__all'>
          <CustomCheckbox
            checked={allCheck}
            onChange={handleAllCheck}
            label={t('poiMenu.showAll')}
          />
        </div>
        <div className='space-poi__poi'>
          {Object.keys(sortPoint).map(objKey => {
            return sortPoint[objKey].map((points, index, array) => {
              return (
                <>
                  {index === 0 && objKey !== 'empty' &&
                    <>
                      <CustomCheckbox
                        onChange={(e) => onCategoryChange(objKey)}
                        label={objKey || 'no name'}
                        isBoldLabel={true}
                        checked={categoryCheck[objKey]}
                      />
                    </>
                  }
                  <CustomCheckbox
                    onChange={(e) => onCheckPoi(e, points.id)}
                    onLabelClick={(e) => onLabelClick(e, points.matterPortId)}
                    checked={points.checked}
                    label={renderLabelWithIcon(points)}
                  />
                  {index === array.length - 1 &&
                    <div></div>}
                </>
              )
            }
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Poi