import {
    SIGN_IN,
    SHOW_LOADER,
    HIDE_LOADER,
    SET_LANGUAGE,
    SHOW_ERROR_MODAL,
    HIDE_ERROR_MODAL,
    SET_POI,
    SET_POI_CHECKED,
    SET_CONFIGS,
    SET_SPACE,
} from './constantTypes'

export const signInAction = data => {
    return {
        type: SIGN_IN,
        payload: data
    }
};

export const showLoaderAction = () => {
    return {
        type: SHOW_LOADER
    }
};

export const hideLoaderAction = () => {
    return {
        type: HIDE_LOADER
    }
};

export const setLanguage = (lang) => {
    return {
        type: SET_LANGUAGE,
        payload: lang
    }
};

export const showSimpleModalAction = ({ title, text }) => {
    return {
        type: SHOW_ERROR_MODAL,
        payload: { title, text },
    }
};

export const hideSimpleModalAction = () => {
    return {
        type: HIDE_ERROR_MODAL,
    }
};

export const setPoi = (payload) => {
    return {
        type: SET_POI,
        payload: payload
    }
};

export const setPoiChecked = (payload) => {
    return {
        type: SET_POI_CHECKED,
        payload: payload
    }
};

export const setConfigs = (configs) => {
    return {
        type: SET_CONFIGS,
        payload: configs
    }
}

export const setSpace = (space) => {
    return {
        type: SET_SPACE,
        payload: space
    }
}
